#crewList-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#crewList-card {
    background-color: #d9d9d9;
    width: 1135px;
    max-width: 95%;
    margin-top: 42px;
    border-radius: 40px 40px 0px 0px;
    height: calc(100vh - 42px - 50px);

    display: flex;
    flex-direction: column;
}

#crewList-card-list-container {
 
    /* border: 1px solid red; */
    /* width: 100%; */
    flex: 1;
    padding: 26px 26px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;

    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

}