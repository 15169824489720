.filterItem-container {
    position: relative;
}



.filterItem-button {
    position: relative;
    margin-left: 6px;
    padding: 7px 0px;
    background-color: #ffffff84;
    border-radius: 8px;
    transition: background-color 0.1s ease-in-out;
}

.filterItem-button:hover {
    cursor: pointer;
    background-color: #ffffff;
}

.filterItem-button-text {
    font-size: 13px;
    color: #606060;
    font-weight: 300;
    user-select: none;
    text-align: center;
}

.filterItem-dropdown-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

}

.filterItem-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: none;
    z-index: 99;

}

.filterItem-dropdown {
    position: absolute;
    background-color: #d9d9d9;
    min-width: 120px;
    max-height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 30px;
    left: 0px;
    z-index: 999;
    text-align: center;
    border-radius: 8px;

}

/* hide scrollbar but allow scrolling */
.filterItem-dropdown {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

.filterItem-dropdown::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}


.filterItem-dropdown-item {

    width: 100%;
    height: 30px;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;


}
.filterItem-dropdown-item:hover {
    background-color: #ffffff84;
    cursor: pointer;
}

.filterItem-container-active .filterItem-button {
    background-color: #606060;
}

.filterItem-container-active .filterItem-button p {
    color: white;
    font-weight: 600;
}

.filterItem-reset-button {
    position: absolute;
    right: 2px;
    top: 20%;
}

@media screen and (max-width: 874px) {
    
    .filterItem-button {
        margin-right: 6px;
        margin-left: 0px
    }

}