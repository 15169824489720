.nav-bar {

    height: 50px;
    background-color: #606060;
    left: 0;
    right: 0;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    
}

.nav-left-expand {
    display: block;
}

.nav-left-shrink {
    display: none;
}

.nav-title {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin-right: 20px;
    align-self: center;
    user-select: none;
}

.nav-title *{
    display: inline;
}

.nav-replacer {
    height: 50px;
}

.nav-shipmanager-a:hover {
    cursor: pointer;
}


.nav-subdirectory-container {
    font-weight: 200;
}

.nav-subdirectory {
    transition: 0.1s color ease-in-out;
}

.nav-subdirectory-container:not(:last-of-type) .nav-subdirectory:hover {
    color: #b3b3b3;
    cursor: pointer;
}

.nav-icon {
    margin-left: 18px;
}
.nav-icon:hover {
    cursor: pointer;
}

@media screen and (max-width: 646px) {

    .nav-left-expand {
        display: none;
    }

    .nav-left-shrink {
        display: block;
    }

    .nav-title {
        display: flex;
        flex-direction: row;
        font-size: 20px;
    }

    .nav-subdirectory-container {
        font-weight: 600;
    }

    .nav-arrow-a {
        /* border: 1px solid red; */
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-arrow-a:hover {
        cursor: pointer;
    }

}

@media print {

    .nav-replacer {
        display: none;
    }

    .nav-bar {
        display: none;
    }

}
