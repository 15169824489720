.new-ship-card {
    width: 242px;
    height: 263px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #6060604d;
    color: #6060604d;
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* border: 1px solid red */
}

@media screen and (max-width: 645px) {
    .new-ship-card {
        width: calc(242*1.2px);
        height: calc(263*1.2px);
        border-radius: calc(24*1.2px);
    }
}

@media screen and (max-width: 588px) {
    .new-ship-card {
        margin-right: 0px;
    }
    
}