.button-top-container {
    /* flex-grow: 8; */
    height: 65px;
    background-color: #d9d9d9;
    border-radius: 14px;
    /* padding: 1000x 100px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0px 10px; */
}

.button-contents-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    
}



.button-svg {
    flex: 1;
    /* margin-left: 20px; */
}

.button-name {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    user-select: none;
    flex: 2;
}

@media screen and (max-width: 918px) {
    .button-top-container {
        height: 55px;
    }
    .button-name {
        font-size: 16px;
    }
    .button-svg {
        scale: 0.7;
    }
}