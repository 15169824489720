@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

#logInPageTopContainer {

    background-color: #d6d6d6;
    min-height: 100vh;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
}

#logInFormContainer {
    transform: scale(150%);
}
#logInPageTopContainer form {
    text-align: center;
    display: flex;
    flex-direction: column;
}

#logInPageTopContainer form input{
    height: 27px;
    width: 240px;
    max-width: 90vw;
    border-radius: 7px;
    border: none;
    background-color: rgba(255,255,255,0.5);
    font-weight: 200;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    padding: 0px 10px;
    margin-bottom: 9px;
    color: black;
    
}

#logInPageTopContainer form input::placeholder {

    color: rgba(0,0,0,0.3);

}

#logInPageTopContainer form input:focus {
    outline: none;
}


#logInPageTopContainer form button {

    width: 60px;
    height: 24px;
    border-radius: 7px;
    border: none;
    background-color: #606060;
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 9px;
    font-weight: 300;
    text-align: center;
    margin: auto;

}



#logInPageTopContainer h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
    user-select: none;
}