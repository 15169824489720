#issue-details-top-container {

    box-sizing: border-box;
    width: 100%;
    max-width: 1280px;
    /* border: 1px solid red; */
    margin-top: 50px;
    padding: 0px 55px;
    position: relative;

}

#issue-details-back-button {

    box-sizing: border-box;
    border: none;
    background: none;
    cursor: pointer;
    color: #606060;
    padding-bottom: 4px;
    /* background-color: #606060; */
    /* border-bottom: 1px solid #d9d9d9; */
    font-weight: 300;
    font-size: 12px;
    /* border-radius: 5px; */
    margin-bottom: 10px;

}

#issue-details-back-button svg {

    transform: translateY(1px);

}

#issue-details-top-banner {
    
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid blue; */
    margin-bottom: 30px;

}

#issue-details-top-banner > * {
    /* border: 1px solid red; */
}

#issue-details-title-container {
    width: 60%;
    /* margin-bottom: 30px; */

}

#issue-details-date {

    font-size : 16px;
    color: #606060;
    font-weight: 300;
    margin-bottom: 2px;

}

#issue-details-title {

    font-size : 34px;
    color: #000;
    font-weight: 700;
    margin-bottom: 4px;

}

#issue-details-postedBy {

    font-size: 16px;
    color: #606060;
    font-weight: 300;
}

#issue-details-postedBy > span {

    font-weight: 600;
    color: black;

}

#issue-details-severity {

    /* position: absolute; */
    box-sizing: border-box;
    width: 126px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 3px;

}

#issue-content-container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid blue; */

}

#issue-content-left {

    flex: 1;
    max-width: 60%;
    box-sizing: border-box;
    padding-right: 55px;
    /* border: 1px solid red; */
}

.issue-content-section {
    
    margin-bottom: 30px;
    
}

.issue-content-section h2 {

    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;

}

.issue-content-section p {

    font-size: 16px;
    font-weight: 400;
    color: #000;

}

#issue-content-attachments-list {

    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: auto;
}

.issue-content-attachment {
    position: relative;
    min-width: 240px;
    min-height: 180px;
    max-width: 240px;
    max-height: 180px;
    border-radius: 18px;
    margin-right: 15px;

}

.issue-content-attachment-image {

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px;

}

.issue-content-attachment-name {

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    color: white;
    border-radius: 0px 0px 18px 18px;


}

.issue-content-attachment-name > span {
    font-size: 18px;
    color: white;
    position: absolute;
    bottom: 15px;
    left: 18px;
    font-weight: 600;
}

#issue-content-right {

    width: 350px;
    max-width: 35%;
    /* border: 1px solid green; */
}

#issue-content-comments-list {

    list-style-type: none;

}
.issue-comment {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid black; */
    border-radius: 20px;
    padding: 20px;
    background-color: #d9d9d9;
    margin-bottom: 15px;
}

.issue-comment-info {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin-bottom: 15px;
    color: #606060;
}

.issue-comment-by {
    flex: 1;
    box-sizing: border-box;
    padding-right: 10px;
}

.issue-comment-by > span {

    font-size: 14px;
    color: #606060;
    font-weight: 300;

}

.issue-comment-by > span > span {
    
    font-weight: 500;
    color: black;

}

.issue-comment-date > span {

    font-weight: 300;
    font-size: 14px;
    color: #606060;

}

.issue-comment-content {
    margin-bottom: 15px;
}

.issue-comment-content > span {

    font-weight: 500;
    font-size: 16px;
    white-space: pre-wrap;
    word-wrap: break-word;

}

.issue-comment-content textarea {
    box-sizing: border-box;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    resize: vertical;
    width: 100%;
    border: 1px solid white;
    background: none;
    
}

#issue-comment-new-submit {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #606060;
    color: white;
    font-weight: 700;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 708px) {

    #issue-details-top-container {
        padding: 0px 6%;
        margin-top: 30px;
    }

    #issue-details-top-banner {

        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* border: 1px solid blue; */

    }

    #issue-details-severity {

        position: initial;
        margin-bottom: 15px;
        transform-origin: bottom left;
        transform: scale(0.85);
        /* border: 1px solid red; */

    }

    #issue-details-title-container {
        width: 100%;
    }

    #issue-content-container {
        flex-direction: column;
    }


    #issue-content-left {
        width: 100%;
        max-width: none;
        padding: 0;
    }

    #issue-content-right {
        width: 100%;
        max-width: none;
    }

    .issue-content-attachment {
        position: relative;
        width: 180px;
        height: 135px;
        border-radius: 13.5px;
        margin-right: 15px;
    
    }

    .issue-content-section {
    
        margin-bottom: 45px;
        
    }



}

@media print {

    #issue-content-container {
        flex-direction: column;
    }

    #issue-content-right {
        width: 100%;
        max-width: none;
    }

    #issue-content-left {
        max-width: none;
    }

    #issue-comment-new {
        display: none;
    }



}