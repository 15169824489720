#buttons-top-container {

    /* width: 50%; */
    border-right: 0.5px solid #d9d9d9;
    height: 303px;
}

#buttons-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    column-gap: 32px;
    row-gap: 23px;
    padding: 32px;
    /* border: 1px solid black; */
    /* background-color: #a8a8a84a; */
}

@media screen and (max-width: 918px) {
    
        #buttons-top-container {
            border-right: none;
            border-bottom: 0.5px solid #d9d9d9;
            height: auto;
        }
    
        #buttons-grid-container {
            padding: 20px;
            column-gap: 20px;
            row-gap: 18px;
        }
}

@media screen and (max-width: 645px) {

    #buttons-top-container {
        border-right: none;
        border-bottom: 0.5px solid #d9d9d9;
        height: auto;
    }

    #buttons-grid-container {
        padding: 20px;
        column-gap: 20px;
        row-gap: 18px;
    }

}
