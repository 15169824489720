#shipListPageTopContainer {
    background-color: #d6d6d6;
    min-height: 100vh;
    height: auto;
    
}

#shipGrid {
    padding: 35px;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;
}

.card-button {
    transition: 0.2s all ease-in-out;
}

.card-button:hover {
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
    cursor: pointer;
}

.card-button:active {
    box-shadow: none;
    transform: translateY(2px);
}


@media screen and (max-width: 588px) {
    #shipGrid {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        padding: 42px;
    }

}