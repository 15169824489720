@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  /* border: 1px solid red; */
  -webkit-tap-highlight-color: transparent;
  /* zoom: 98%; */
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  height: 100vh;
  width: 100vw;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
