#shipDashboard-banner {
    height: 204px;
    background-color: #d9d9d9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#shipDashboard-banner-left {
    margin-left: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    /* border: 1px solid red; */
}


#shipDashboard-banner-left svg{
    width: 144px;
	height: 144px;
}



#shipDashboard-banner-left-text-container {
    margin-left: 18px;
}

#shipDashboard-banner-shipname {
    font-size: 36px;
    font-weight: 700;
    color: #000000;
}

#shipDashboard-banner-shipimo {
    font-size: 20px;
    font-weight: 300;
    color: #000000;
}

#shipDashboard-latest-position {

    width: 210px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-right: 5%; */
    /* border: 1px solid black; */

}

#shipDashboard-latest-position #latest-position-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

#shipDashboard-latest-position #latest-position-title p {
    font-size: 15px;
    font-weight: 300;
    color: #000000;
}

#shipDashboard-latest-position #latest-position-data {

    font-size: 15px;
    font-weight: 700;
    color: #000000;
    margin-left: 16px;
    margin-top: 2px;


}

#shipDashboard-latest-position #show-map-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    margin-top: 2px;
    user-select: none;
}

#shipDashboard-latest-position #show-map-button p {
    font-size: 12px;
    font-weight: 200;
    color: #000000;
}

#shipDashboard-latest-position #show-map-button svg {
    margin-top: 2px;
    margin-left: 1px;
}

#shipDashboard-banner #shortcut-buttons-container {

    /* width: 290px; */
    /* border: 1px solid black; */
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 76px;
    gap: 55px;

}

#copy-gps-svg {
    margin-left: 10px;
    transform: translateY(2px);
}

#shipDashboard-banner #shortcut-buttons-container .shortcut-button svg {
    fill: black;
}

@media screen and (max-width: 918px) and (min-width: 646px) {

    #shipDashboard-banner {
        position:fixed;
        top: 50px;
        left: 0px;
        flex-direction: column;
        width: 40vw;
        align-items: flex-start;
        min-height: calc(100vh - 50px);
        overflow: scroll;
    }

    #shipDashboard-banner-left {
        display: flex;
        flex-direction: column;
        margin: 10px 20px;
        margin-top: 30px;
        max-width: 90%;
    }

    #shipDashboard-banner-shipname {
        font-size: 30px;
        font-weight: 700;
        color: #000000;
        text-align: left;
    }
    
    #shipDashboard-banner-shipimo {
        font-size: 16.66px;
        font-weight: 300;
        color: #000000;
        text-align: left;
    }

    #shipDashboard-banner-left > svg {
        width: 120px;
        height: 120px;
    }

    #shipDashboard-latest-position {
        margin: 20px 30px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    #shipDashboard-banner #shortcut-buttons-container {
        padding: 0;
        margin: 20px 40px;
    }

}


@media screen and (max-width: 645px) {


    #shipDashboard-banner {
        flex-direction: column;
        width: 100vw;
        align-items: flex-start;
        height: auto;

    }

    #shipDashboard-banner-left {
        margin: 10px 20px;
        margin-top: 30px;
    }

    #shipDashboard-banner-left > svg {
        width: 120px;
        height: 120px;
    }


    #shipDashboard-latest-position {
        margin: 20px 30px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    #shipDashboard-banner #shortcut-buttons-container {
        padding: 0;
        margin: 20px 40px;
    }



}