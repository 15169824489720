#gallery-container {

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
}

#gallery-photos-container{

    width: 1200px;
    max-width: 100%;
    /* border: 1px solid black; */
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gallery-image {

    max-height: 300px;
    max-width: calc(100% - 20px);
    flex:auto;
    margin: 5px;
    border: 1px solid black;
    object-fit: cover;
}