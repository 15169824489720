#shipDashboardTopContainer {
    min-height: 100vh;
    height: auto;
}

#shipDashboard-grid {

    display: grid;
    grid-template-columns: 1fr 1fr;

}



@media screen and (max-width: 918px) {

    #shipDashboard-grid {

        display: flex;
        flex-direction: column;

    }

}

@media screen and (max-width: 918px) and (min-width: 646px) {

    #shipDashboard-grid {

        position: absolute;
        top: 0;
        right: 0;
        width: 60vw;
        margin-top: 50px;
    }

}

