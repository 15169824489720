#latest-updates-top-container {

    /* width: 50%; */
    height: 303px;
    border-left: 0.5px solid #d9d9d9;
    /* padding: 16px 23px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    /* border: 1px solid red; */
}

#update-line {
    position:absolute;
    left: 28px;
    top: 15px;
    z-index: -1;
    width: 281px;
    height: 100%;
    max-width: 50.8%;
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position: left;
}

#latest-updates-title {

    font-size: 25px;
    font-weight: 600;
    user-select: none;
    margin-top: 16px;
    margin-left: 23px;

}

#latest-updates-container {

    /* width: 100%; */
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
    column-gap: 50px;
    /* row-gap: 50px; */
    /* border: 1px solid red; */
    margin: 20px 23px;


}
  

.update-point {

    display: flex;
    /* width: 50%; */
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 10.5px;
    /* border: 1px solid black; */
}   

.update-point-vector {

    margin-top: 3px;
    margin-right: 5px;

}

.update-description {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.1px;
}

.update-date {
    font-size: 12px;
    font-weight: 200;
    color: #565656c7;
}

#see-all-arrow {

    margin-left: 5px;
    margin-top: 5px;

}

@media screen and (max-width: 918px) {

    #latest-updates-top-container {
        border: none;
        border-top: 0.5px solid #d9d9d9;
    }
    
}