.crewlist-personCard-container {

    width: 100%;
    background-color: #60606099;

    border-radius: 18px;
    padding: 9px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 26px;
    
    position: relative;

}

.crewlist-personCard-avatar-container {

    width: 80px;
    height: 80px;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crewlist-personCard-info-container {

    margin-left: 30px;
    flex: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.crewlist-personCard-info-name {

    font-size: 20px;
    font-weight: 600;
    color: white;

}

.crewlist-personCard-info-role {

    font-size: 12px;
    font-weight: 200;
    color: white;

}


.crewlist-personCard-buttons-view {

    padding: 6px 12px;
    border-radius: 8px;
    background-color: #d6d6d6;
    color: #606060;
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    bottom: 11px;
    right: 16px;
    border: none;
}

