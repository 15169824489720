.folderButton-top-container {

    box-sizing: border-box;

    width: 240px;
    height: 60px;

    border: none;
    border-radius: 12px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    background-color: #60606099;
}

.folder-icon {
    margin-left: 24px;
}

.folderButton-name {

    flex: 1;

    margin-left: 24px;

    font-size: 18px;
    font-weight: 600;
    color: white;
    text-align: left;
}
