#shared-files-search-bar {

    padding: 24px 30px 18px 30px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#shared-files-searchBar-wrapper {
    flex: 6;
    /* width: 50%; */
    /* border: 1px solid red; */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* min-width: 300px; */
}

#shared-files-search {
    border: none;
    flex: 1;
    background-color: #ffffff84;
    border-radius: 11px;
    padding: 11px 36px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11.625 10.5H11.0325L10.8225 10.2975C11.5829 9.41552 12.0008 8.28952 12 7.125C12 6.16082 11.7141 5.21829 11.1784 4.4166C10.6427 3.61491 9.88137 2.99007 8.99058 2.62109C8.09979 2.25211 7.11959 2.15557 6.17394 2.34367C5.22828 2.53178 4.35964 2.99608 3.67786 3.67786C2.99608 4.35964 2.53178 5.22828 2.34367 6.17394C2.15557 7.11959 2.25211 8.09979 2.62109 8.99058C2.99007 9.88137 3.61491 10.6427 4.4166 11.1784C5.21829 11.7141 6.16082 12 7.125 12C8.3325 12 9.4425 11.5575 10.2975 10.8225L10.5 11.0325V11.625L14.25 15.3675L15.3675 14.25L11.625 10.5ZM7.125 10.5C5.2575 10.5 3.75 8.9925 3.75 7.125C3.75 5.2575 5.2575 3.75 7.125 3.75C8.9925 3.75 10.5 5.2575 10.5 7.125C10.5 8.9925 8.9925 10.5 7.125 10.5Z' fill='%23606060'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: 12px 10px;
    background-size: 18px 18px;
    font-size: 13px;
    color: #606060;
    font-weight: 400;
    transition: background-color 0.2s ease-in-out;
}

#shared-files-search::placeholder {

    font-size: 13px;
    color: #606060b1;
    user-select: none;
    font-weight: 300;
}

#shared-files-search:hover {
    background-color: #ffffff;
}

#shared-files-search:focus {
    outline: none;
}

