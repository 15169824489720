#shipInfo-container {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

#shipInfo-card {

    background-color: #d9d9d9;
    width: 1136px;
    max-width: 95%;
    margin: 42px 0px;
    border-radius: 40px 40px;

}

#shipInfo-card > div:not(:last-child) {
    border-bottom: 1px solid white;
}

#shipInfo-card-header {
    padding: 44px 55px;
}

#shipInfo-card-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#shipInfo-card-header-image {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 38px;
}

#shipInfo-card-header-text h1 {

    font-size: 38px;
    font-weight: 700;
    color: #000

}

#shipInfo-card-header-text p {
    font-size: 18px;
    font-weight: 300;
    color: #606060
}

.shipInfo-card-section {
    padding: 25px 55px;
}

.shipInfo-card-section h3 {
    font-size: 21px;
    font-weight: 700;
}

.shipInfo-card-section-wrapper {

    margin: 10px 0px;
    column-count: 2;
    line-height: 1.75;
    column-fill: balance;    
}

.shipInfo-card-section-key {
    font-size: 16px;
    font-weight: 300;
    color: #606060;
}

.shipInfo-card-section-value {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

@media screen and (max-width: 760px) {

    #shipInfo-card-header {
        padding: 24px 35px;
    }

    .shipInfo-card-section {
        padding: 24px 35px;
    }

    #shipInfo-card-header-image {
        width: 160px;
        height: 160px;
    }

    #shipInfo-card-header-text h1 {
        font-size: 30px;
    }

    #shipInfo-card-header-text p {
        font-size: 13px;
    }

    .shipInfo-card-section-wrapper {
        column-count: 1;
    }

    .shipInfo-card-section h3 {
        font-size: 18px;
    }

    .shipInfo-card-section-key {
        font-size: 14px;
    }

    .shipInfo-card-section-value {
        font-size: 14px;
    }

}

@media screen and (max-width: 465px) {

    #shipInfo-card {
        border-radius: 30px 30px;
    }

    #shipInfo-card-header-image {
        max-width: 30vw;
        max-height: 30vw;
    }

    #shipInfo-card-header-text h1 {
        font-size: 6vw;
    }

    #shipInfo-card-header-text p {
        font-size: 3vw;
    }

    .shipInfo-card-section-wrapper {
        line-height: 1.25;
    }

    .shipInfo-card-section h3 {
        font-size: 4.5vw;
    }



    .shipInfo-card-section-key {
        font-size: 3.5vw;
    }

    .shipInfo-card-section-value {
        font-size: 3.5vw;
    }

}

@media print {

    #shipInfo-container {
        border: 1px solid red;
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100vh;
    }

    #shipInfo-container {
        border: 1px solid red;
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #shipInfo-card {
        position: fixed;
        top: 0px;
        margin: auto;
        scale: 0.8;
        max-width: none;
        width: 120%;
    }

}
