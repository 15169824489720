.ship-card {
    width: 242px;
    height: 263px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #606060;
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* border: 1px solid red */
    /* margin-right: 35px; */
    /* margin-bottom: 35px; */
}

.ship-card-img {
    width: 242px;
    height: 186px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 24px 24px 0px 0px;
}

.ship-card-info {
    color: white;
    width: 100%;
    margin-top: 11px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.ship-card-info-text {
    margin-left: 16px;
}

.ship-card-info-text h3 {
    font-size: 22px;
    font-weight: 600;
}

.ship-card-info-text p {
    font-size: 12px;
    font-weight: 300;
}
.ship-card-more-button {
    margin-right: 8px;
}

@media screen and (max-width: 645px) {

    .ship-card {
        width: calc(242*1.2px);
        height: calc(263*1.2px);
        border-radius: calc(24*1.2px);
    }

    .ship-card-img {
        width: 100%;
        height: calc(186*1.2px);
        border-radius: 24px 24px 0px 0px;
    }

    .ship-card-info {
        margin-top: calc(11*1.2px);
    }

    .ship-card-info-text {
        margin-left: calc(16*1.2px);
    }

    .ship-card-info-text h3 {
        font-size: calc(22*1.2px);
    }

    .ship-card-info-text p {
        font-size: calc(12*1.2px);
    }

    .ship-card-more-button {
        margin-right: calc(8*1.2px);
    }

    .ship-card-more-button {
        font-size: calc(24*1.2px);
    }



}
