.issueCard-container {

    width: 100%;
    background-color: #60606099;
    border-radius: 18px;
    padding: 15px 16px;
    position: relative;
    margin-bottom: 26px;
}

.issueCard-container > * {
    padding: 0px;
}

.issueCard-date {
    color: white;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    right: 16px;
    opacity: 0.5; 
}

.issueCard-main-container {

    color: white;
    line-height: 1.2;

}

.issueCard-main-title {
    font-size: 20px;
    font-weight: 600;
}

.issueCard-main-subInfo {

    font-size: 12px;
    font-weight: 200;
}

.issueCard-main-severity {
    font-size: 13px;
    font-weight: 600;

}

.issueCard-view-button {
    padding: 6px 12px;
    border-radius: 8px;
    background-color: #d6d6d6;
    color: #606060;
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    bottom: 11px;
    right: 16px;
    border: none;
}

@media screen and (max-width: 646px) {

    .issueCard-date {
        position: initial;
        font-size: 12px;
        margin-bottom: 3px;
    }

    .issueCard-main-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .issueCard-main-container > * {

        margin-bottom: 3px;

    }

    .issueCard-view-button {
        position: initial;
        margin-top: 6px;
    }

}
