.chat-button {


    height: 72px;
    width: 72px;

    background-color: #606060;
    border-radius: 50%;
    box-shadow: 0px 8px 10px #00000040;

    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    transition: 0.15s all ease-in-out;

}

.chat-button svg {

    transform: scaleX(-1);

}

.chat-button:active {

    box-shadow: none;
    scale: 0.9;

}


@media screen and (max-width: 645px) {
    
        .chat-button {
    
            bottom: 10px;
            right: 10px;
            scale: 0.8;
    
        }

        .chat-button:active {
            scale: calc(0.8 * 0.9);
        }
    
}