#sharedFiles-container {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

#sharedFiles-card {

    background-color: #d9d9d9;
    width: 1135px;
    max-width: 95%;
    margin: 42px 0px;
    border-radius: 40px;
    min-height: 100vh;

    display: flex;
    flex-direction: column;

}

#sharedFiles-card-contents-wrapper {

    flex: 1;
    padding-bottom: 48px;
}

.sharedFiles-card-section {
    margin: 0px 42px;
    margin-top: 28px;
}

.sharedFiles-card-section > h2 {

    font-size: 28px;
    user-select: none;

}

.sharedFiles-card-list {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px 30px;
}
