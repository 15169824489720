.fileButton-top-container {

    box-sizing: border-box;

    width: 240px;
    height: 60px;

    border: none;
    border-radius: 12px;

    display: flex;
    justify-content: flex-start;
    align-items: center;


}

.file-icon {

    margin-left: 24px;

}

.fileButton-name {

    flex: 1;
    margin-left: 24px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    text-align: left;


}