#shared-files-upload-button {

    box-sizing: border-box;

    width: 104px;
    height: 38px;
    margin-left: 18px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #606060;

    border: none;
    border-radius: 11px;

    color: white;
    font-size: 14px;
    font-weight: 500;

    user-select: none;

}

#shared-files-upload-button > svg {

    padding-right: 5px;

}
