#App {
  width: 100%;
  height: 100%;
}

.clickableOpacity {
  cursor: pointer;
  transition: opacity 0.1s;
}

.clickableOpacity:hover,
.clickableOpacity:focus {
  opacity: 0.8;
  outline: none;
}

.clickableOpacity:active {
  opacity: 0.1;
}

.clickableBrightness {
  transition: all 0.1s ease-in-out;
}

.clickableBrightness:hover,
.clickableBrightness:focus {
  filter: brightness(0.95);
  cursor: pointer;
}

.clickableBrightness:active {
  filter: brightness(0.75);
}